import React from 'react'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import {
  BsCalendarEvent,
  BsCalendarCheck,
  BsFillTagsFill
} from 'react-icons/bs'
import { CgSandClock } from 'react-icons/cg'
import { HiOutlineDocumentDownload } from 'react-icons/hi'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'
import Banner from '../components/Banner'
import {
  Adsense,
  Comment,
  EachTag,
  Layout,
  PrevNextNavBar,
  TableOfContents,
  ScrollToTop,
  SEO,
  SubBookbar,
} from '../components'

const PostTemplate = ({ data, pageContext }) => {
  const { titleAlt, siteUrl } = useSiteMetadata()
  const siteName = titleAlt
  const {
    mdx: {
      frontmatter: {
        title,
        subtitle,
        date,
        category,
        subcategory,
        tags,
        slug,
        featuredImage,
        featuredImageAlt,
        caption,
        photographer,
        photoUrl,
        platform,
        platformUrl,
        embeddedImages,
        attachments,
      },
      excerpt,
      body,
      id,
      tableOfContents,
      timeToRead,
      parent: {
        fields: { gitLogLatestDate },
      },
    },
  } = data

  const categoryWithHyphen = category.replace(/\s+/g, '-').toLowerCase()
  const subcategoryWithHyphen = subcategory.replace(/\s+/g, '-').toLowerCase()

  const edges = pageContext.edges.find(
    item =>
      item.node.frontmatter.title === title &&
      item.node.frontmatter.category === category
  )
  const featuredImageSrc =
    featuredImage.childImageSharp.gatsbyImageData.images.fallback.src

  const dateLastUpdated = gitLogLatestDate? gitLogLatestDate : null

  if (pageContext.pages) {
    // const { count } = pageContext.pages.node
  }
  
  return (
    <Layout>
      <SEO
        article
        title={title}
        subtitle={subtitle}
        description={excerpt}
        image={featuredImageSrc}
        imageAlt={featuredImageAlt}
        datePublished={date}
        dateLastUpdated={dateLastUpdated}
      />
      <Wrapper>
        {SubBookbar ? (
          <div className="sub-bookbar">
            <SubBookbar currentTitle={title} />
          </div>
        ) : null}
        {/* post info */}
        <article>
          <figure>
            <GatsbyImage
              image={getImage(featuredImage)}
              alt={featuredImageAlt}
              className="main-img"
              objectPosition="center center"
            />
            <figcaption className="main-img-caption">
              {caption}
              <div className="main-img-src">
                <a href={photoUrl ? photoUrl : null}>
                  {photographer ? photographer : null}
                </a>
                {photographer ? ` - ` : null}
                <a href={platformUrl ? platformUrl : siteUrl}>
                  {platform ? platform : siteName}
                </a>
              </div>
            </figcaption>
          </figure>
          <div className="post-info">
            <div className="post-category">
              <Link to={`/blog/category/${categoryWithHyphen}/`}>
                <span className="category-name">{category}</span>
              </Link>
              <Link
                to={`/blog/category/${categoryWithHyphen}/${subcategoryWithHyphen}/`}
              >
                <span className="subcategory-name">{subcategory}</span>
              </Link>
            </div>
            <hgroup>
              <h1>{title}</h1>
              <h2>{subtitle}</h2>
            </hgroup>
            <div className="post-details">
              <span className="date">
                <span className="date-updated">
                  <span>
                    <BsCalendarCheck className="icon" />
                    Last Updated&nbsp;&nbsp;
                  </span>
                  {dateLastUpdated}
                </span>
                <span className="date-published">
                  <span>
                    <BsCalendarEvent className="icon" />
                    Published&nbsp;&nbsp;
                  </span>
                  {date}
                </span>
              </span>
              <span className="tags">
                <BsFillTagsFill className="icon" />
                <EachTag tags={tags} />
              </span>
              <span className="minutes">
                <CgSandClock className="icon" />
                {timeToRead}분
              </span>
              {/* {count && (
                <span className="count">
                  <BsEye className="icon" />
                  {count.toLocaleString()}
                </span>
              )} */}
            </div>
            {attachments && (
              <ul className="files">
                {attachments.map((attachment, index) => {
                  return (
                    <li key={`file-${index}`}>
                      <HiOutlineDocumentDownload className="icon" />
                      <a href={attachment.publicURL} download>
                        실습 파일 {index + 1}
                      </a>
                    </li>
                  )
                })}
              </ul>
            )}
            <div className="underline"></div>
          </div>
          {typeof tableOfContents.items === 'undefined' ? null : (
            <div className="main-table-of-content">
              <TableOfContents
                tableOfContents={tableOfContents}
                postUrl={`/blog/${slug}/`}
              />
            </div>
          )}
          <MDXRenderer embeddedImages={embeddedImages}>{body}</MDXRenderer>
          <PrevNextNavBar {...edges} />
          <Comment identifier={id} title={title} />
        </article>
        {/* banner component */}
        <div className="banner">
          <Banner />
          <div className="banner-table-of-content">
            <TableOfContents
              tableOfContents={tableOfContents}
              postUrl={`/blog/${slug}/`}
            />
            <Adsense type="display-sidebar-bottom" />
          </div>
        </div>
        <ScrollToTop />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug }, published: { eq: true } }) {
      excerpt(pruneLength: 77, truncate: true)
      frontmatter {
        title
        subtitle
        category
        subcategory
        tags
        date(formatString: "YYYY-MM-DD")
        slug
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
        }
        featuredImageAlt
        caption
        photographer
        photoUrl
        platform
        platformUrl
        embeddedImages {
          childImageSharp {
            gatsbyImageData(width: 1200, placeholder: BLURRED)
          }
        }
        attachments {
          publicURL
        }
      }
      body
      id
      tableOfContents
      timeToRead
      parent {
        ... on File {
          fields {
            gitLogLatestDate(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`

const Wrapper = styled.section`
  width: 85vw;
  max-width: 1200px;
  margin: 7rem auto 4rem auto;
  .sub-bookbar {
    display: none;
  }
  .main-img {
    max-height: 35rem;
    margin: 0 auto;
  }
  .main-img-caption {
    text-align: center;
    margin: 0 auto;
    margin-top: 0.875rem;
    display: block;
    font-style: normal;
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--clr-caption-text-color);
    .main-img-src {
      text-align: center;
      a:link {
        color: var(--clr-hyperlink);
        background-color: transparent;
        text-decoration: none;
      }
      a:hover {
        border-bottom: 1px solid var(--clr-hyperlink);
      }
    }
  }
  .post-info {
    margin: 1.5rem auto 1rem auto;
    text-align: center;
    max-width: var(--fixed-width);
    .post-category {
      display: grid;
      grid-template-rows: 1fr 1fr;
      row-gap: 0.75rem;
    }
    span {
      padding: 0.5rem;
      text-transform: uppercase;
      letter-spacing: var(--spacing);
    }
    .category-name {
      background: var(--clr-base);
      color: var(--clr-white);
      border-radius: var(--radius);
      font-weight: 700;
    }
    .subcategory-name {
      background: var(--clr-base-secondary);
      color: var(--clr-white);
      border-radius: var(--radius);
      margin-left: 0.25rem;
      font-weight: 700;
    }
    .post-details {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 2rem auto 0.5rem auto;
      color: var(--clr-content-semiemphasis);
      .date {
        display: grid;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        justify-content: start;
        .icon {
          font-size: 1rem;
          margin-right: 0.5rem;
        }
        .date-published,
        .date-updated {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 0;
          text-transform: capitalize;
          span {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
      .minutes {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          font-size: 1rem;
          margin-right: 0.375rem;
        }
      }
      .count {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          font-size: 1.2rem;
          margin-right: 0.375rem;
        }
      }
      .tags {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .icon {
          margin-right: 0.75rem;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      span {
        color: var(--clr-content-semiemphasis);
      }
    }
    h2 {
      color: var(--clr-content-semiemphasis);
    }
    h3 {
      margin: 1rem 0;
      color: var(--clr-content-semiemphasis);
    }
    p {
      color: var(--clr-content);
    }
    .files {
      display: grid;
      align-items: center;
      justify-content: right;
      li {
        display: flex;
        align-items: center;
        a {
          font-size: 1rem;
          color: var(--clr-hyperlink);
          margin-left: 0.25rem;
        }
      }
    }
    .underline {
      width: 5rem;
      height: 1px;
      background: var(--clr-hover-border-background-dark);
      margin: 0.25rem auto 0 auto;
    }
  }
  .main-table-of-content {
    width: 85vw;
    margin: 0.5rem auto;
    max-width: var(--fixed-width);
  }
  .banner-table-of-content {
    display: none;
  }
  .ads-multiplex {
    display: grid;
    margin: 1rem auto 1rem auto;
    max-width: var(--fixed-width);
  }
  @media (min-width: 400px) {
    & {
      .post-info {
        .post-category {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }
    }
  }
  @media (min-width: 1064px) {
    & {
      width: 92vw;
      column-gap: 2.5rem;
      margin-top: 5.5rem;
    }
    .sub-bookbar {
      display: inline-block;
      position: sticky;
      top: 7rem;
      width: 167px;
    }
    .post-info {
      width: 92vw;
      max-width: var(--fixed-width);
      margin: 1.5rem auto 0 auto;
    }
    .main-img {
      width: 60%;
      height: 15rem;
      display: block;
      margin: 0 auto;
    }
  }
  @media (min-width: 1260px) {
    & {
      width: 95vw;
      display: grid;
      grid-template-columns: 200px 1fr 300px;
      column-gap: 1.5rem;
      margin-top: 7rem;
    }
    .main-img {
      width: 90%;
    }
    .banner-table-of-content {
      display: grid;
      position: -webkit-sticky;
      position: sticky;
      top: 1.5rem;
      align-self: start;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
`
export default PostTemplate
